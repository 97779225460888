import BusinessIcon from '@mui/icons-material/Business';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import LogoutIcon from '@mui/icons-material/Logout';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { useTheme } from '@mui/material/styles';
import { useCallback, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import logo from '@maya/assets/images/maya-logo.png';
import { SIDEBAR_WIDTH } from '@maya/constants';
import { useIsLargeScreen } from '@maya/hooks/useMediaQuery';
import useTranslate from '@maya/hooks/useTranslate';
import { useAppDispatch, useAppSelector } from '@maya/store/hooks';
import { logout, selectIsBranchAdmin, selectIsMayaAdmin, selectIsOffice } from '@maya/store/slices/auth';
import PayrollIcon from './icons/PayrollIcon';

import type { FC, PropsWithChildren } from 'react';

const SelectedListItemButton = ({
  to,
  selectedMatcher,
  children
}: PropsWithChildren<{
  to: string;
  selectedMatcher: (pathname: string) => boolean;
}>) => {
  const theme = useTheme();

  return (
    <ListItemButton
      selected={selectedMatcher(window.location.pathname)}
      component={Link}
      to={to}
      sx={{ borderRadius: '4px', '&.Mui-selected': { '.MuiSvgIcon-root': { color: theme.palette.primary.main } } }}
    >
      {children}
    </ListItemButton>
  );
};

const prefixMatcher =
  (...prefix: string[]) =>
  (pathname: string) => {
    return Boolean(prefix.find((p) => pathname.startsWith(p)));
  };

export interface SidebarProps {
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
}

const Sidebar: FC<SidebarProps> = ({ open, onOpen, onClose }) => {
  const t = useTranslate();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isMayaAdmin = useAppSelector(selectIsMayaAdmin);
  const isOffice = useAppSelector(selectIsOffice);
  const isBranchAdmin = useAppSelector(selectIsBranchAdmin);

  const handleLogout = useCallback(() => {
    dispatch(logout(navigate, t));
  }, [dispatch, navigate, t]);

  const isLargeScreen = useIsLargeScreen();

  // TODO: Change menu items based on permissions and user type
  const menuItems = useMemo(() => {
    if (isMayaAdmin) {
      return (
        <>
          <ListItem key="companies" data-testid="companies" disablePadding sx={{ borderRadius: '4px' }}>
            <SelectedListItemButton to="/company/list" selectedMatcher={prefixMatcher('/company')}>
              <ListItemIcon>
                <BusinessIcon />
              </ListItemIcon>
              <ListItemText primary={t('company.list.header')} />
            </SelectedListItemButton>
          </ListItem>
          <ListItem key="users" data-testid="users" disablePadding>
            <SelectedListItemButton to="/user/list" selectedMatcher={prefixMatcher('/user')}>
              <ListItemIcon>
                <SupervisedUserCircleIcon />
              </ListItemIcon>
              <ListItemText primary={t('user.list.header')} />
            </SelectedListItemButton>
          </ListItem>
        </>
      );
    }

    if (isOffice) {
      return (
        <>
          <ListItem key="patients" data-testid="patients" disablePadding>
            <SelectedListItemButton to="/patient/list" selectedMatcher={prefixMatcher('/patient')}>
              <ListItemIcon>
                <LocalHospitalIcon />
              </ListItemIcon>
              <ListItemText primary={t('patients.list.header')} />
            </SelectedListItemButton>
          </ListItem>
          <ListItem key="payroll" data-testid="payroll" disablePadding>
            <SelectedListItemButton to="/payroll" selectedMatcher={prefixMatcher('/payroll')}>
              <ListItemIcon>
                <PayrollIcon />
              </ListItemIcon>
              <ListItemText primary={t('payroll.header')} />
            </SelectedListItemButton>
          </ListItem>
          <ListItem key="employees" data-testid="employees" disablePadding>
            <SelectedListItemButton to="/employee/list" selectedMatcher={prefixMatcher('/employee')}>
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary={t('employees.list.header')} />
            </SelectedListItemButton>
          </ListItem>
          {isBranchAdmin ? (
            <ListItem key="settings" data-testid="settings" disablePadding>
              <SelectedListItemButton to="/settings/visit-type" selectedMatcher={prefixMatcher('/settings')}>
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary={t('settings.header')} />
              </SelectedListItemButton>
            </ListItem>
          ) : null}
        </>
      );
    }

    return (
      <>
        <ListItem key="dashboard" data-testid="dashboard" disablePadding>
          <SelectedListItemButton to="/dashboard/summary" selectedMatcher={prefixMatcher('/dashboard', '/visit')}>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary={t('dashboard.header')} />
          </SelectedListItemButton>
        </ListItem>
        <ListItem key="payroll" data-testid="payroll" disablePadding>
          <SelectedListItemButton to="/payroll" selectedMatcher={prefixMatcher('/payroll')}>
            <ListItemIcon>
              <PayrollIcon />
            </ListItemIcon>
            <ListItemText primary={t('payroll.header')} />
          </SelectedListItemButton>
        </ListItem>
      </>
    );
  }, [isBranchAdmin, isMayaAdmin, isOffice, t]);

  return (
    <SwipeableDrawer
      sx={{
        width: SIDEBAR_WIDTH,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: SIDEBAR_WIDTH,
          boxSizing: 'border-box'
        }
      }}
      variant={isLargeScreen ? 'permanent' : undefined}
      anchor="left"
      open={open}
      onOpen={onOpen}
      onClose={onClose}
    >
      <Box
        component="img"
        src={logo}
        alt="Maya Logo"
        sx={{
          maxWidth: '100%',
          height: 56,
          alignSelf: 'center',
          margin: '8px 0'
        }}
      />
      <Box sx={{ padding: '8px', display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
        <Divider />
        <List sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>{menuItems}</List>
        <Box sx={{ flexGrow: 1 }} />
        <List disablePadding>
          <ListItem key="logout" disablePadding>
            <ListItemButton onClick={handleLogout} sx={{ borderRadius: '4px' }}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary={t('auth.logout')} />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </SwipeableDrawer>
  );
};

export default Sidebar;
