import Box from '@mui/material/Box';

import type { FC, ReactNode } from 'react';

export interface BaseIconProps {
  title?: string;
  children: ReactNode;
}

const BaseIcon: FC<BaseIconProps> = ({ title, children }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        userSelect: 'none',
        width: '1em',
        height: '1em',
        fontSize: '1.5rem'
      }}
      title={title}
    >
      <Box
        component="svg"
        focusable="false"
        viewBox="0 0 24 24"
        aria-hidden="true"
        tabIndex={-1}
        sx={{
          fill: 'currentColor'
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default BaseIcon;
